/* Tabulator v4.6.2 (c) Oliver Folkerd */
/*******************************
         Site Settings
*******************************/
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of $px variables
*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Text ---*/
/*--- Colored Headers ---*/
/*--- Colored Border ---*/
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
    Brand Colors
--------------------*/
/*--------------
  Page Heading
---------------*/
/*-------------------
        Page
--------------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descendors are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds $size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/* Columns */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*******************************
             Table
*******************************/
/*-------------------
       Element
--------------------*/
/*--------------
     Parts
---------------*/
/* Table Row */
/* Table Cell */
/* Table Header */
/* Table Footer */
/* Responsive Size */
/*-------------------
       Types
--------------------*/
/* Definition */
/*--------------
    Couplings
---------------*/
/*--------------
     States
---------------*/
/* Positive */
/* Negative */
/* Error */
/* Warning */
/* Active */
/*--------------
     Types
---------------*/
/* Attached */
/* Striped */
/* Selectable */
/* Sortable */
/* Colors */
/* Inverted */
/* Basic */
/* Padded */
/* Compact */
/* Sizes */
.tabulator {
  position: relative;
  background-color: #FFFFFF;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin: 1em 0em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571rem;
  color: rgba(0, 0, 0, 0.87);
  -ms-transform: translatez(0);
  transform: translatez(0);
  /* Red */
  /* Orange */
  /* Yellow */
  /* Olive */
  /* Green */
  /* Teal */
  /* Blue */
  /* Violet */
  /* Purple */
  /* Pink */
  /* Brown */
  /* Grey */
  /* Black */
}

.tabulator[tabulator-layout="fitDataFill"] .tabulator-tableHolder .tabulator-table {
  min-width: 100%;
}

.tabulator.tabulator-block-select {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tabulator .tabulator-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  background-color: #F9FAFB;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  font-style: none;
  font-weight: bold;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  background-color: #F9FAFB;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid #999;
  background: #dae1e7;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  position: relative;
  padding: 0.92857em 0.78571em;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button {
  padding: 0 8px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button:hover {
  cursor: pointer;
  opacity: .6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #999;
  padding: 1px;
  background: #fff;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #ddd;
  overflow: hidden;
  margin-right: -1px;
}

.tabulator .tabulator-header .tabulator-col:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col.ui-sortable-helper {
  position: absolute;
  background-color: #dae1e7 !important;
  border: 1px solid #ddd;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 25px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #dae1e7;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-arrow {
  border-top: 6px solid #666;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical .tabulator-col-content .tabulator-col-title {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  text-orientation: mixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip .tabulator-col-title {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-arrow {
  right: calc(50% - 6px);
}

.tabulator .tabulator-header .tabulator-frozen {
  display: inline-block;
  position: absolute;
  z-index: 10;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #ddd;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #ddd;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  min-width: 600%;
  background: white !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: white !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 600%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableHolder {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-height: 100%;
  min-width: 100%;
}

.tabulator .tabulator-tableHolder .tabulator-placeholder span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.tabulator .tabulator-tableHolder .tabulator-table {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: visible;
  color: #333;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: bold;
  background: #f2f2f2 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #ddd;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #ddd;
}

.tabulator .tabulator-col-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-footer {
  padding: 0.78571em 0.78571em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  background: #F9FAFB;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  white-space: nowrap;
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  margin: -0.78571em -0.78571em 0.78571em -0.78571em;
  text-align: left;
  background: white !important;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  font-weight: bold;
  background: white !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -0.78571em;
  border-bottom: none;
}

.tabulator .tabulator-footer .tabulator-paginator {
  color: #555;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page-size {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  margin: 0 2px;
  padding: 2px 5px;
  border: 1px solid #aaa;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #d00;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: .5;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.tabulator .tabulator-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  font-weight: bold;
  font-size: 16px;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  border: 4px solid #333;
  color: #000;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  border: 4px solid #D00;
  color: #590000;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.positive, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.positive {
  box-shadow: 0px 0px 0px #A3C293 inset;
  background: #FCFFF5 !important;
  color: #21BA45 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.positive:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.positive:hover {
  background: #f7ffe6 !important;
  color: #13ae38 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.negative, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.negative {
  box-shadow: 0px 0px 0px #E0B4B4 inset;
  background: #FFF6F6 !important;
  color: #DB2828 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.negative:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.negative:hover {
  background: #ffe7e7 !important;
  color: #d41616 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.error, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.error {
  box-shadow: 0px 0px 0px #E0B4B4 inset;
  background: #FFF6F6 !important;
  color: #DB2828 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.error:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.error:hover {
  background: #ffe7e7 !important;
  color: #d12323 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.warning, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.warning {
  box-shadow: 0px 0px 0px #C9BA9B inset;
  background: #FFFAF3 !important;
  color: #F2C037 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.warning:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.warning:hover {
  background: #fff4e4 !important;
  color: #f1bb29 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.active, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.active {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.87) inset;
  background: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.active:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.active:hover {
  background: #f7ffe6 !important;
  color: #13ae38 !important;
}

.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.active, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.disabled:hover, .tabulator .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell.active {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.2);
}

.tabulator.inverted {
  background: #333333;
  color: rgba(255, 255, 255, 0.9);
  border: none;
}

.tabulator.inverted .tabulator-header {
  background-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.9);
}

.tabulator.inverted .tabulator-header .tabulator-col {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.tabulator.inverted .tabulator-tableHolder .tabulator-table .tabulator-row {
  color: rgba(255, 255, 255, 0.9);
  border: none;
}

.tabulator.inverted .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.tabulator.inverted .tabulator-footer {
  background: #FFFFFF;
}

.tabulator.striped .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.tabulator.celled {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.tabulator.celled .tabulator-header .tabulator-col {
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}

.tabulator.celled .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}

.tabulator[class*="single line"] .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: none;
}

.tabulator.red {
  border-top: 0.2em solid #DB2828;
}

.tabulator.inverted.red {
  background-color: #DB2828 !important;
  color: #FFFFFF !important;
}

.tabulator.orange {
  border-top: 0.2em solid #F2711C;
}

.tabulator.inverted.orange {
  background-color: #F2711C !important;
  color: #FFFFFF !important;
}

.tabulator.yellow {
  border-top: 0.2em solid #FBBD08;
}

.tabulator.inverted.yellow {
  background-color: #FBBD08 !important;
  color: #FFFFFF !important;
}

.tabulator.olive {
  border-top: 0.2em solid #B5CC18;
}

.tabulator.inverted.olive {
  background-color: #B5CC18 !important;
  color: #FFFFFF !important;
}

.tabulator.green {
  border-top: 0.2em solid #21BA45;
}

.tabulator.inverted.green {
  background-color: #21BA45 !important;
  color: #FFFFFF !important;
}

.tabulator.teal {
  border-top: 0.2em solid #00B5AD;
}

.tabulator.inverted.teal {
  background-color: #00B5AD !important;
  color: #FFFFFF !important;
}

.tabulator.blue {
  border-top: 0.2em solid #2185D0;
}

.tabulator.inverted.blue {
  background-color: #2185D0 !important;
  color: #FFFFFF !important;
}

.tabulator.violet {
  border-top: 0.2em solid #6435C9;
}

.tabulator.inverted.violet {
  background-color: #6435C9 !important;
  color: #FFFFFF !important;
}

.tabulator.purple {
  border-top: 0.2em solid #A333C8;
}

.tabulator.inverted.purple {
  background-color: #A333C8 !important;
  color: #FFFFFF !important;
}

.tabulator.pink {
  border-top: 0.2em solid #E03997;
}

.tabulator.inverted.pink {
  background-color: #E03997 !important;
  color: #FFFFFF !important;
}

.tabulator.brown {
  border-top: 0.2em solid #A5673F;
}

.tabulator.inverted.brown {
  background-color: #A5673F !important;
  color: #FFFFFF !important;
}

.tabulator.grey {
  border-top: 0.2em solid #767676;
}

.tabulator.inverted.grey {
  background-color: #767676 !important;
  color: #FFFFFF !important;
}

.tabulator.black {
  border-top: 0.2em solid #1B1C1D;
}

.tabulator.inverted.black {
  background-color: #1B1C1D !important;
  color: #FFFFFF !important;
}

.tabulator.padded .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 1em 1em;
}

.tabulator.padded .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 20px;
}

.tabulator.padded .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 1em 1em;
}

.tabulator.padded.very .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 1.5em 1.5em;
}

.tabulator.padded.very .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 26px;
}

.tabulator.padded.very .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 1.5em 1.5em;
}

.tabulator.compact .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0.5em 0.7em;
}

.tabulator.compact .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 12px;
}

.tabulator.compact .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 0.5em 0.7em;
}

.tabulator.compact.very .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 0.4em 0.6em;
}

.tabulator.compact.very .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 10px;
}

.tabulator.compact.very .tabulator-tableHolder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 0.4em 0.6em;
}

.tabulator-row {
  position: relative;
  box-sizing: border-box;
  min-height: 22px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.tabulator-row.tabulator-selectable:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.87) inset;
  background: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.tabulator-row.tabulator-selected {
  background-color: #9ABCEA;
}

.tabulator-row.tabulator-selected:hover {
  background-color: #769BCC;
  cursor: pointer;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  pointer-events: none !important;
  z-index: 15;
}

.tabulator-row .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  display: inline-block;
  position: absolute;
  background-color: inherit;
  z-index: 10;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #ddd;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #ddd;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 0.78571em 0.78571em;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1D68CD;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input, .tabulator-row .tabulator-cell.tabulator-editing select {
  border: 1px;
  background: transparent;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #DB2828;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input, .tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: #DB2828;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  border: 1px solid #333;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #333;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: .7;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  border-right: 1px solid #ddd;
  border-top: 1px solid #999;
  padding: 5px;
  padding-left: 10px;
  background: #fafafa;
  font-weight: bold;
  min-width: 100%;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  border-bottom: 0;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid #666;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: #666;
}

.tabulator-menu {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.tabulator-menu .tabulator-menu-item {
  padding: 5px 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: .5;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
  background: #F9FAFB;
}

.tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid #ddd;
}

.tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  max-height: 200px;
  background: #FFFFFF;
  border: 1px solid #ddd;
  font-size: 14px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  padding: 4px;
  color: #333;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #FFFFFF;
  background: #1D68CD;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #FFFFFF;
  background: #1D68CD;
}

.tabulator-edit-select-list .tabulator-edit-select-list-notice {
  padding: 4px;
  text-align: center;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  border-bottom: 1px solid #ddd;
  padding: 4px;
  padding-top: 6px;
  color: #333;
  font-weight: bold;
}

.tabulator-print-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

body.tabulator-print-fullscreen-hide > *:not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;
}

.tabulator-print-table .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.tabulator-print-table .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  border: 1px solid #333;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tabulator-print-table .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #333;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #333;
}
